import Markdown from 'react-markdown';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState(undefined);

    const fetchDocument = () => {
        fetch(`/documents/privacy/${i18n.language}.md`)
            .then(res => res.text())
            .then(data => setContent(data))
            .catch(error => {
                setContent(null);
            });
    };

    i18n.on('languageChanged', () => {
        fetchDocument();
    });

    useEffect(() => {
        fetchDocument();
    }, []);

    return (
        <Page title={t("privacy.title")} img="info-circle" titleUpper>
            <section>
                <Markdown>{content}</Markdown>
            </section>
        </Page>
    );
}

export default Privacy;
