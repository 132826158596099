import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useScreenSize from '../hooks/useScreenSize';
import useDarkmode from '../hooks/useDarkmode';
import i18next from 'i18next';
import '../assets/css/navbar.css';
import LoginPopup from './Authentication';
import useLoggedIn from '../hooks/useLoggedIn';

const Dropdown = (props) => {
    return (
        <div class="dropdown pointer">
            <span className={props.className}>{props.label} <i class="fa fa-angle-down" aria-hidden="true"></i></span>
            <div class="dropdown-content">
                {props.children}
            </div>
        </div>
    );
}

const NavBar = (props) => {
    const { t } = useTranslation();
    const screenSize = useScreenSize();
    const loggedIn = useLoggedIn();
    const [showSideMenu, setShowSideMenu] = useState(false);
    const [darkmode, toggleDarkmode] = useDarkmode();

    const changeLanguage = (lng) => {
        i18next.changeLanguage(lng);
        localStorage.setItem('lang', lng);
    }

    const NavBarElement = (propsChild) => {
        return (
            <li>
                <span >
                    <a href={propsChild.path} className={props.url === propsChild.path ? 'active' : ''}>
                        {propsChild.icon ? <i className={'fa fa-' + propsChild.icon} aria-hidden="true"></i> : ''}
                        {propsChild.title}
                    </a>
                </span>
            </li>
        );
    }

    const LoginButton = () => {
        return (
            <LoginPopup trigger={<li><span><span className="flex align-center gap-10 pointer lexend semi-bold"><i className="fa fa-sign-in fa-lg" aria-hidden="true"></i>{t('authentication.button')}</span></span></li>} />
        );
    }
                    
    const dropdown = (
        <Dropdown label={t('language')} className="lexend">
            <button className='full-width' onClick={() => changeLanguage('en')}><span class="fi fi-gb radius-3"></span>English</button>
            <button className='full-width' onClick={() => changeLanguage('fr')}><span class="fi fi-fr radius-3"></span>Français</button>
            <button className='full-width' onClick={() => alert("Not available yet \nContact me if you would like to assist in translating the website into additional languages ;)")}><span class="fi fi-ma radius-3"></span>العربية</button>
        </Dropdown>
    );
    const navBarElements = [
        (<NavBarElement path="/" title={t('home.title')} icon="home" />),
        (<NavBarElement path="/goals" title={t('goals.title')} icon="futbol-o" />),
        (<NavBarElement path="/goal-of-fame" title={t('goalOfFame.title')} icon="trophy" />),
        (<NavBarElement path="/players" title={t('players.title')} icon="users" />),
        (loggedIn ? <NavBarElement path="/profile" title={t('profile.title')} icon="user" /> : <LoginButton />)
    ];

    useEffect(() => {
        const overlay = document.querySelector('.side-menu__overlay');
        if (overlay !== null)
            if (!showSideMenu) {
                setTimeout(() => {
                    overlay.classList.add('hidden');
                }, 300);
            } else {
                overlay.classList.remove('hidden');
            }
    }, [showSideMenu]);


    return (
        <div className="nav-bar">
            <div className="nav-bar__logo flex gap-10 align-center">
                <img src="/assets/img/dimagoals_logo.png" alt="DimaGoals logo" className="logo" style={{ width: '40px' }} />
                <a href="/">
                    <span className="lexend black uppercase font-l">DimaGoals</span>
                </a>
            </div>
                <div className="nav-bar__menu">
                    {
                        screenSize.width > 1035 ?
                            <ul>
                                <li>
                                    <span>
                                        {dropdown}
                                    </span>
                                </li>
                                {navBarElements.map(element => element)}
                                <li className="lexend black pointer" onClick={toggleDarkmode}>
                                    <i className={`fa fa-${darkmode ? 'sun-o' : 'moon-o'} fa-lg on-background-text`} aria-hidden="true" />
                                </li>
                            </ul> :
                            <ul>
                                <li>
                                    <i className={`burger fa fa-${showSideMenu ? 'angle-right fa-2x' : 'bars'} fa-lg pointer `} aria-hidden="true" onClick={() => setShowSideMenu(!showSideMenu)}></i>
                                </li>
                                <div className={'side-menu__overlay ' + (showSideMenu ? '' : 'invisible')} onClick={() => setShowSideMenu(false)}></div>
                                <div className={'side-menu ' + (showSideMenu ? '' : 'hidden')}>
                                    <div className='side-menu__content'>
                                        <span className="lexend black primary-text uppercase font-l text-center" style={{ marginLeft: '-20px' }}>DimaGoals</span>
                                        <div className='flex justify-center gap-20'>
                                            <span className="flex gap-10 align-center lexend pointer" onClick={toggleDarkmode}>
                                                <i className={`fa fa-${darkmode ? 'sun-o' : 'moon-o'} fa-lg on-background-text`} aria-hidden="true" />
                                                {darkmode ? 'Light mode' : 'Dark mode'}
                                            </span>
                                        <div className='side-menu__dropdown'>
                                            {dropdown}
                                        </div>
                                        </div>
                                        <div className='side-menu__elements'>
                                            {navBarElements.map(element => element)}
                                        </div>
                                    </div>
                                </div>
                            </ul>
                    }
                </div> 
        </div>
    );
}

export default NavBar;