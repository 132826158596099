import { useTranslation } from "react-i18next";
import Page from "../components/Page";

export default function NotFound() {
    const { t } = useTranslation();

    return (
        <Page title={t("notFound.title")} img="exclamation-triangle" titleUpper>
            <p className="text-center">
                {t("notFound.message")}
            </p>
        </Page>
    );
}