import { List } from "./List";
import GoalComponent from "./GoalComponent";
import { useTranslation } from "react-i18next";
import AddGoalButton from "./AddGoalButton";

const GoalsList = ({ goals, showPlayer, title = undefined, button = true, leaderboard = false }) => {
    const { t } = useTranslation();

    return (
        <List title={title === undefined ? t("goals.word")+'s' : title} button={button ? <AddGoalButton /> : ''}>
            {goals.map((goal, index) => (
                <li className="list-item pop" key={goal.id}>
                    {leaderboard ? <span className="leaderboard">{index + 1}</span> : ''}
                    <GoalComponent goal={goal} showPlayer={showPlayer} showMinute={!leaderboard} showVotes={leaderboard} />
                </li>
            ))}
            {
                goals.length === 0 ?
                    <p className="secondary-text text-center" style={{ margin: '30px 0' }}>{t('goals.noGoals')}</p> : ''
            }
        </List>
    );
};

export default GoalsList;