import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CreatableSelect from 'react-select/creatable';
import { createPlayer, getPlayers } from '../api';

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string, value: string) => ({
  label,
  value: value
});

const PlayerSelect = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [players, setPlayers] = useState<Option[]>([]);
  const [value, setValue] = useState<Option | null>();

  const styles = {
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: !state.isFocused ? 'var(--secondary-background-color)' : 'var(--secondary-background-color-light)',
      ':hover': {
        backgroundColor: 'var(--secondary-background-color-light)'
      },
      ':active': {
        backgroundColor: 'var(--secondary-background-color)'
      },
    }),
  };

  useEffect(() => {
    getPlayers('', 1, 100)
      .then(data => {
        setPlayers(data.results.map((player: { id: number; full_name: string; }) => ({ value: player.id, label: player.full_name })));
      })
      .catch(error => {
        alert(t("errors.fetch"));
      });
  }, []);

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    const spaceIndex = inputValue.indexOf(' ');
    const firstName = inputValue.substring(0, spaceIndex) || inputValue;
    const lastName = inputValue.substring(spaceIndex + 1) || inputValue;
    createPlayer({ first_name: firstName, last_name: lastName })
      .then((data) => {
        const newOption = createOption(inputValue, data.id.toString());
        setIsLoading(false);
        setPlayers([...players, newOption]);
        setValue(newOption);
      })
      .catch((error) => {
        alert(t("errors.create"));
        setIsLoading(false);
      });
  };

  return (
    <CreatableSelect
      styles={styles}
      classNamePrefix="select"
      name="scorer"
      placeholder={t("goals.form.create.scorerPlaceholder")}
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={(newValue) => setValue(newValue)}
      onCreateOption={handleCreate}
      options={players}
      value={value}
      required
    />
  );
};

export default PlayerSelect;