import config from "../config.json";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Page from "../components/Page";
import GoalsList from "../components/GoalsList";
import { getCompetitions, getGoals, getNationalTeam } from "../api";
import useDarkmode from "../hooks/useDarkmode";
import Container from "../components/Container";

export default function Goals() {
    const { t } = useTranslation();
    const [darkmode] = useDarkmode();
    const [data, setData] = useState(undefined);
    const [goals, setGoals] = useState(undefined);
    const [competitions, setCompetitions] = useState(undefined);
    const [filter, setFilter] = useState({ competition: undefined, nationalTeam: false });
    const [nationalTeam, setNationalTeam] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);

    const loadMoreGoals = () => {
        setLoadingMore(true);
        if (hasNextPage)
            getGoals('', currentPage + 1)
                .then(newData => {
                    setGoals(prevGoals => [...prevGoals, ...newData.results]);
                    setCurrentPage(prevPage => prevPage + 1);
                    setHasNextPage(newData.next !== null);
                })
                .catch(error => {
                    console.error('Error loading more goals:', error);
                })
                .finally(() => {
                    setLoadingMore(false);
                });
        else {
            document.onscroll = null;
        }
    };

    useEffect(() => {
        getNationalTeam(config.nationalTeam)
            .then(data => setNationalTeam(data))
            .catch(error => {
                setNationalTeam(null);
            });
    }, []);

    useEffect(() => {
        if (data === undefined) return;
        if (filter.competition !== undefined) {
            setGoals(data.results.filter(goal => goal.competition.id === filter.competition));
        } else if (filter.nationalTeam) {
            setGoals(data.results.filter(goal => goal.team.id === nationalTeam.id));
        }
        else {
            setGoals(data.results);
        }
    }, [data, filter]);

    useEffect(() => {
        getGoals()
            .then(data => {
                setData(data);
                setHasNextPage(data.next !== null);
            })
            .catch(error => {
                setData(null);
            });
    }, []);

    useEffect(() => {
        getCompetitions()
            .then(data => setCompetitions(data))
            .catch(error => {
                setCompetitions(null);
            });
    }, []);

    const handleScroll = (e) => {
        if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
            loadMoreGoals();
        }
    };

    document.onscroll = handleScroll;

    if (data === null) {
        return (
            <Page url="/goals" title={t('goals.title')}>
                <p>{t('errors.fetch')}</p>
            </Page>
        )
    }

    return (
        <Page url="/goals" title={t('goals.title')} img="futbol-o" titleUpper>
            <Container title={t('goals.filters.title')} titleBorder margin={15} wrapper>
                <div className="flex-column gap-10" style={{ marginTop: '15px' }}>
                    <h4 className="no-margin">{t('goals.filters.nationalTeam')}</h4>
                    <div className="flex-column gap-10" style={{ marginLeft: '15px', padding: '5px 0' }}>
                        <div className={"flex align-center gap-5 clickable-bg pointer radius " + (!filter.nationalTeam ? 'disabled-clickable' : '')} key={nationalTeam.id} onClick={() => setFilter({ ...filter, nationalTeam: !filter.nationalTeam, competition: undefined })}>
                            <img src={nationalTeam.logo} alt={nationalTeam.name} style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
                            <label className="pointer" >{nationalTeam.name}</label>
                        </div>
                    </div>
                    <h4 className="no-margin">{t('goals.filters.competition')}</h4>
                    <div className="flex-column gap-10" style={{ marginLeft: '15px', padding: '5px 0' }}>
                        {
                            competitions === undefined ? <span className="loader"></span> :
                                competitions === null ? <p>{t('errors.fetch')}</p> :
                                    competitions.map(competition => (
                                        <div className={"flex align-center gap-5 clickable-bg pointer radius " + (filter.competition !== competition.id ? 'disabled-clickable' : '')} key={competition.id} onClick={() => (filter.competition === competition.id) ? setFilter({ ...filter, competition: undefined }) : setFilter({ ...filter, competition: competition.id, nationalTeam: false })}>
                                            <img src={darkmode ? competition.logo : competition.logo.replace('/dark', '')} alt={competition.name} style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
                                            <label className="pointer" htmlFor={`competition-${competition.id}`}>{competition.name}</label>
                                        </div>
                                    ))
                        }
                    </div>
                </div>
            </Container>
            {
                goals === undefined ? <span className="loader"></span> :
                    goals.length === 0 ? <p>{t('goals.word')}</p> :
                        <GoalsList goals={goals} showPlayer />
            }
            {
                goals && goals.length > 0 && hasNextPage && loadingMore &&
                    <span className="loader-horizontal" />
            }
        </Page>
    );
}