import { useEffect, useState } from 'react';

class ClassWatcher {

    constructor(targetNode, classToWatch, classAddedCallback, classRemovedCallback) {
        this.targetNode = targetNode
        this.classToWatch = classToWatch
        this.classAddedCallback = classAddedCallback
        this.classRemovedCallback = classRemovedCallback
        this.observer = null
        this.lastClassState = targetNode.classList.contains(this.classToWatch)

        this.init()
    }

    init() {
        this.observer = new MutationObserver(this.mutationCallback)
        this.observe()
    }

    observe() {
        this.observer.observe(this.targetNode, { attributes: true })
    }

    disconnect() {
        this.observer.disconnect()
    }

    mutationCallback = mutationsList => {
        for(let mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                let currentClassState = mutation.target.classList.contains(this.classToWatch)
                if(this.lastClassState !== currentClassState) {
                    this.lastClassState = currentClassState
                    if(currentClassState) {
                        this.classAddedCallback()
                    }
                    else {
                        this.classRemovedCallback()
                    }
                }
            }
        }
    }
}

const useDarkmode = () => {
    const [darkmode, setDarkmode] = useState(false);

    useEffect(() => {
        const darkmode = localStorage.getItem('darkmode');
        if (darkmode === 'true') {
            setDarkmode(true);
        }
    }, []);

    const toggleDarkmode = () => {
        setDarkmode(!darkmode);
        localStorage.setItem('darkmode', !darkmode);
        document.body.classList.toggle('dark');
    }

    new ClassWatcher(document.body, 'dark', () => {
        setDarkmode(true);
    }, () => {
        setDarkmode(false);
    });

    return [darkmode, toggleDarkmode];
}

export default useDarkmode;