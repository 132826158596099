import config from './config'
import Cookies from 'js-cookie'

const token = Cookies.get('token');
const headers = {
    'Authorization': `Token ${token}`
};

function logged() {
    return token !== undefined;
}

export async function getPlayer(id) {
    const response = await fetch(`${config.apiEndpoint}/players/${id}/`, { method: "GET" })
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json()
    return data
}

export async function getPlayers(filter = '', page = 1, page_size) {
    if (page_size === undefined) page_size = null;
    const response = await fetch(`${config.apiEndpoint}/players/?page=${page}&page_size=${page_size}${((filter.length > 0) ? ('&' + filter) : '')}`, { method: "GET" })
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json()
    return data
}

export async function getGoals(filter = '', page = 1, page_size = null) {
    if (typeof page === 'string') {
        page = page.split('=').pop()
    }
    const response = await fetch(`${config.apiEndpoint}/goals/?page=${page}&page_size=${page_size}&${((filter.length > 0) ? ('&' + filter) : '')}`, { method: "GET" })
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json()
    return data
}

export async function  getAllGoals() {
    const response = await fetch(`${config.apiEndpoint}/goals/all/`, {
        method: "GET",
        headers: headers,
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json()
    return data
}

export async function getGoal(id) {
    const response = await fetch(`${config.apiEndpoint}/goals/${id}/`, { method: "GET" })
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json()
    return data
}

export async function getCompetitions() {
    const response = await fetch(`${config.apiEndpoint}/competitions/`, { method: "GET" })
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json()
    return data
}

export async function getTeams() {
    const response = await fetch(`${config.apiEndpoint}/teams/`, { method: "GET" })
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json()
    return data
}

export async function getNationalTeam() {
    const response = await fetch(`${config.apiEndpoint}/teams/?name=Morocco`, { method: "GET" })
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json()
    return data[0]
}

export async function createPlayer({ first_name, last_name, birth_date = null, photo = null }) {
    const response = await fetch(`${config.apiEndpoint}/players/create/`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        body: JSON.stringify({ first_name, last_name, birth_date, photo })
    })
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json()
    return data
}

export async function createTeam({ name, logo = ""}) {
    const response = await fetch(`${config.apiEndpoint}/teams/create/`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        body: JSON.stringify({ name, logo })
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
}

export async function createCompetition({ name, logo = '' }) {
    const response = await fetch(`${config.apiEndpoint}/competitions/create/`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        body: JSON.stringify({ name, logo })
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
}

export async function createGoal(formData) {
    const response = await fetch(`${config.apiEndpoint}/goals/create/`, {
        method: "POST",
        headers: headers,
        body: formData
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
}

export async function suggestClip(goalId, formData) {
    const response = await fetch(`${config.apiEndpoint}/goals/${goalId}/video/`, {
        method: "POST",
        headers: logged() ? headers : {},
        body: formData
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
}

export async function reportClip(goalId, formData) {
    const response = await fetch(`${config.apiEndpoint}/goals/${goalId}/report/`, {
        method: "POST",
        headers: logged() ? headers : {},
        body: formData
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
}

export async function getVotes() {
    const response = await fetch(`${config.apiEndpoint}/goals/votes/`, {
        method: 'GET',
        headers: headers,
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
}

export async function vote(goalId) {
    const response = await fetch(`${config.apiEndpoint}/goals/${goalId}/vote/`, {
        method: 'POST',
        headers: headers,
    });
    return response.ok;
}

export async function unvote() {
    const response = await fetch(`${config.apiEndpoint}/goals/votes/`, {
        method: 'DELETE',
        headers: headers,
    });
    return response.ok;
}

export async function login(formData) {
    const response = await fetch(`${config.apiEndpoint}/auth/login/`, {
        method: "POST",
        body: formData
    });
    if (response.status === 200) {
        const data = await response.json();
        Cookies.set('token', data.token, { expires: 365 });
        Cookies.set('username', formData.get("username"), { expires: 365 });
        return true;
    } else if (response.status === 400) {
        throw new Error("errors.credentials");
    }
    throw new Error("errors.login");
}

export async function signup(formData) {
    const response = await fetch(`${config.apiEndpoint}/auth/signup/`, {
        method: "POST",
        body: formData
    });
    const data = await response.json();
    if (data.token !== undefined) {
        Cookies.set('token', data.token, { expires: 365 });
        Cookies.set('username', formData.get("username"), { expires: 365 });
        return true;
    } else {
        throw new Error(data.non_field_errors ? data.non_field_errors[0] : data.error);
    }
}

export async function getLeaderboard(month, year) {
    const response = await fetch(`${config.apiEndpoint}/goals/leaderboard/${month}/?year=${year}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
}

export async function getMyProfile() {
    const response = await fetch(`${config.apiEndpoint}/me/`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
    });
    if (!response.ok) {
        throw new Error('errors.400');
    }
    const data = await response.json();
    return data;
}

export async function getSuggestions(filter = '') {
    const response = await fetch(`${config.apiEndpoint}/suggestions/?${filter}=1`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
    });
    if (!response.ok) {
        throw new Error('errors.400');
    }
    const data = await response.json();
    return data;
}

export async function createSuggestion(formData) {
    const response = await fetch(`${config.apiEndpoint}/suggestions/`, {
        method: "POST",
        headers: headers,
        body: formData
    });
    if (!response.ok) {
        throw new Error('errors.post');
    }
    const data = await response.json();
    return data;
}