import { useEffect, useState } from "react";
import GoalsList from "../components/GoalsList";
import Page from "../components/Page";
import { useTranslation } from "react-i18next";
import Countdown from "../components/Countdown";
import { getLeaderboard } from "../api";

const GoalOfFame = () => {
    const { t } = useTranslation();
    const [goals, setGoals] = useState(undefined);
    const date = new Date();
    const thisMonth = date.getMonth() + 1;
    const thisYear = date.getFullYear();
    const [year, setYear] = useState(thisYear);
    const nextMonth = new Date();
    const [month, setMonth] = useState(thisMonth);

    nextMonth.setMonth(thisMonth);
    nextMonth.setDate(1); nextMonth.setHours(0); nextMonth.setMinutes(0); nextMonth.setSeconds(0);

    const handleNext = () => {
        if (month < 12) {
            setMonth(month + 1);
        } else {
            setMonth(1);
            setYear(year + 1);
        }
    };

    const handlePrevious = () => {
        if (month > 1) {
            setMonth(month - 1);
        } else {
            setMonth(12);
            setYear(year - 1);
        }
    };

    useEffect(() => {
        getLeaderboard(month, year)
            .then(data => setGoals(data))
            .catch(error => {
                setGoals(null);
            });
    }, [month, year]);

    return (
        <Page url="/goal-of-fame" title={t('goalOfFame.title')} img="trophy" titleUpper>
            <p className="text-center">{t('goalOfFame.description')}</p>
            <div className="flex justify-between align-center gap-10">
                <button className="" onClick={handlePrevious}><i className="fa fa-chevron-left fa-lg" aria-hidden="true"></i></button>
                <h4 className="title">{t(`utils.months.${month}`)} {year}</h4>
                <button className="" onClick={handleNext}><i className="fa fa-chevron-right fa-lg" aria-hidden="true"></i></button>
            </div>
            {
                month === thisMonth && year === thisYear ? 
                    <Countdown date={nextMonth} />
                    : null
            }
            {
                goals === undefined ? <span className="loader"></span> :
                    goals === null ? <p>{t('errors.fetch')}</p> :
                        <GoalsList goals={goals} button={false} title={t('goalOfFame.leaderboardTitle')} showPlayer leaderboard />
            }
        </Page>
    );
}

export default GoalOfFame;