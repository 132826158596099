import Popup from "reactjs-popup";
import Close from "./Close"
import { useTranslation } from "react-i18next";
import { createGoal } from "../api";
import useLoggedIn from "../hooks/useLoggedIn";
import Cookies from "js-cookie";
import PlayerSelect from "./PlayerSelect.tsx";
import TeamSelect from "./TeamSelect.tsx";
import CompetitionSelect from "./CompetitionSelect.tsx";
import { timecodeToSeconds } from "../utils.js";

const CreateGoalForm = ({close}) => {
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        if (formData.get('timecode_start') || formData.get('timecode_end')) {
            try {
                formData.set('timecode_start', timecodeToSeconds(formData.get('timecode_start')));
                formData.set('timecode_end', timecodeToSeconds(formData.get('timecode_end')));
            } catch (error) {
                formData.delete('timecode_start');
                formData.delete('timecode_end');
            }
        }
        createGoal(formData).then(data => {
            close();
            alert(t("goals.formSent"));
        }).catch(error => {
            alert(t("errors.post"));
        });
    }

    return (
        <form className="flex-column gap-40 align-center justify-center" onSubmit={handleSubmit}>
            <div className="flex-column gap-30 align-center justify-center full-width">
                <input type="hidden" name="contributor" value={Cookies.get('username')} />
                <fieldset>
                    <legend>Joueur</legend>
                    <label>{t("goals.form.create.scorer")}</label>
                    <PlayerSelect />
                </fieldset>
                <fieldset>
                    <legend>Match</legend>
                    <label for="team">{t("goals.form.create.team")}</label>
                    <TeamSelect />
                    
                    <label for="opponent">{t("goals.form.create.opponent")}</label>
                    <TeamSelect name="opponent" />
                    
                    <label for="competition">{t("goals.form.create.competition")}</label>
                    <CompetitionSelect />
                    
                    <label for="date">{t("goals.form.create.matchDate")}</label>
                    <input type="date" name="date" required />
                    
                    <label for="minute">{t("goals.form.create.minute")}</label>
                    <input type="number" name="minute" placeholder={t("goals.form.create.minutePlaceholder")} min={1} max={150} required />
                </fieldset>
                <fieldset>
                    <legend>Clip</legend>
                    <label for="video">{t("goals.form.suggest.videoSource")}</label>
                    <input type="url" name="video" placeholder="https://example.com/video.mp4" />
                    
                    <label for="timecode_start">{t("goals.form.suggest.timecodeStart")}</label>
                    <input name='timecode_start' placeholder="01:43" />
                    
                    <label for="timecode_end">{t("goals.form.suggest.timecodeEnd")}</label>
                    <input name='timecode_end' placeholder="02:12" />
                </fieldset>
                </div>
            <input className="btn btn-primary no-border" type="submit" value={t("goals.form.create.submit")} />
        </form>
    );
};

const AddGoalButton = ({goal = undefined}) => {
    const { t } = useTranslation();
    const loggedIn = useLoggedIn();

    return (
        loggedIn ?
            <Popup trigger={<button className="btn btn-secondary flex gap-5 align-center justify-center no-border" style={{fontSize: "0.8rem"}}><i className="fa fa-plus"></i> {t('goals.add')}</button>} closeOnDocumentClick={false} modal>
                {close => (
                    <div className='flex-column gap-20 align-center justify-center full-width'>
                        <Close onClick={close} />
                        <h3>{t("goals.form.create.title")}</h3>
                        <p className="text-center">{t("goals.form.create.description")}</p>
                        <CreateGoalForm close={close} />
                    </div>
                )}
            </Popup> :
            <Popup trigger={<button className="btn btn-secondary flex gap-5 align-center justify-center no-border" style={{fontSize: "0.8rem"}}><i className="fa fa-plus"></i> {t('goals.add')}</button>} modal>
                {close => (
                    <div className='flex-column gap-20 align-center justify-center full-width'>
                        <Close onClick={close} />
                        <p className="text-center">{t("errors.400")}</p>
                    </div>
                )}
            </Popup>
    )
}

export default AddGoalButton;