import Popup from "reactjs-popup";
import Container from "./Container";
import config from "../config.json";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Close from "./Close";
import { login, signup } from "../api";

const PasswordResetForm = ({ setPasswordResetForm }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handlePasswordReset = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(e.target);
        fetch(config.apiEndpoint + '/auth/password/reset/', {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (response.ok) {
                    alert(t('authentication.passwordResetSuccess'));
                    setLoading(false);
                } else {
                    alert(t('authentication.passwordResetError'));
                    setLoading(false);
                }
            })
            .catch(error => {
                alert(t('authentication.passwordResetError'));
            });
    };

    return (
        <form className="flex-column gap-20" style={{ marginTop: '15px' }} onSubmit={handlePasswordReset}>
            <div className="flex-column gap-10">
                <label htmlFor="email">{t('authentication.email')}</label>
                <input type="email" name="email" placeholder={t('authentication.emailPlaceholder')} required />
            </div>
            <div className="flex justify-center align-center gap-10">
                <input type="submit" className={"btn btn-primary max-content no-border " + (loading ? "btn-loading" : "")} value={t('authentication.passwordResetSubmit')} />
                <button className="link pointer" onClick={() => setPasswordResetForm(false)}>{t('authentication.signin')}</button>
            </div>
        </form>
    );
};

const LoginSignupForm = ({ setTitle, setErrorMessage }) => {
    const { t } = useTranslation();
    const [passwordResetForm, setPasswordResetForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loginForm, setLoginForm] = useState(true);

    const handleLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(e.target);
        login(formData)
            .then(success => {
                if (success)
                    window.location.reload();
            })
            .catch(error => {
                setErrorMessage(t(error.message));
                setLoading(false);
            });
    }

    const handleSignup = (e) => {
        e.preventDefault();

        if (e.target.password.value !== e.target.password_confirmation.value) {
            setErrorMessage(t("errors.passwordsDontMatch"));
            return;
        }

        const formData = new FormData(e.target);
        signup(formData)
            .then(success => {
                if (success)
                    window.location.reload();
            })
            .catch(error => {
                setErrorMessage(t(error.message || "errors.register"));
            });
    }

    useEffect(() => {
        setTitle(loginForm ?
                    (passwordResetForm ?
                        t('authentication.passwordResetTitle') :
                        t('authentication.loginTitle')) :
                    t('authentication.signupTitle')
        );
    }, [loginForm, passwordResetForm, t]);

    return loginForm ? (
        passwordResetForm ?
            <PasswordResetForm setPasswordResetForm={setPasswordResetForm} /> :
            <form className="flex-column gap-20" style={{ marginTop: '15px' }} onSubmit={handleLogin}>
                <div className="flex-column gap-10">
                    <label htmlFor="username">{t('authentication.username')}</label>
                    <input type="text" name="username" placeholder={t('authentication.usernamePlaceholder')} required />
                </div>
                <div className="flex-column gap-10">
                    <label htmlFor="password">{t('authentication.password')}</label>
                    <input type="password" name="password" placeholder={t('authentication.passwordPlaceholder')} required />
                </div>
                <span className="link text-center no-border pointer" onClick={() => setLoginForm(false)}>{t('authentication.dontHaveAccount')}</span>
                <div className="flex flex-wrap justify-center align-center gap-10">
                    <input type="submit" className={"btn btn-primary max-content no-border " + (loading ? "btn-loading" : "")} value={t('authentication.signin')} />
                    <span className="btn btn-secondary" onClick={() => setPasswordResetForm(true)}>{t('authentication.forgotPassword')}</span>
                </div>
            </form>
    ) : (
        <form className="flex-column gap-20" style={{ marginTop: '15px' }} onSubmit={handleSignup}>
            <div className="flex-column gap-10">
                <label htmlFor="email">{t('authentication.username')}</label>
                <input type="text" name="username" placeholder={t('authentication.usernamePlaceholder')} required />
            </div>
            <div className="flex-column gap-10">
                <label htmlFor="email">{t('authentication.email')}</label>
                <input type="email" name="email" placeholder={t('authentication.emailPlaceholder')} required />
            </div>
            <div className="flex-column gap-10">
                <label htmlFor="password">{t('authentication.password')}</label>
                <input type="password" name="password" placeholder={t('authentication.passwordPlaceholder')} required />
            </div>
            <div className="flex-column gap-10">
                <label htmlFor="password">{t('authentication.passwordConfirmation')}</label>
                <input type="password" name="password_confirmation" placeholder={t('authentication.passwordConfirmationPlaceholder')} required />
            </div>
            <span className="link text-center no-border pointer" onClick={() => setLoginForm(true)}>{t('authentication.alreadyHaveAccount')}</span>
            <div className="flex justify-center align-center gap-10">
                <input type="submit" className={"btn btn-primary max-content no-border " + (loading ? "btn-loading" : "")} value={t('authentication.signup')} />
            </div>
        </form>
    );
}

const LoginPopup = ({ trigger }) => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState('');
    const [title, setTitle] = useState(t('authentication.loginTitle'));

    return (
        <Popup trigger={trigger} modal nested closeOnDocumentClick={false}>
            {close => (
                <div className='flex-column gap-20 align-center justify-center full-width'>
                    <Container title={title} titleBorder={true} margin={10}>
                        <Close onClick={close} />
                        {
                            errorMessage !== '' ?
                                <p className="text-center error margin-top">{errorMessage}</p> :
                                ''
                        }
                        <LoginSignupForm setTitle={setTitle} setErrorMessage={setErrorMessage} />
                    </Container>
                </div>
            )}
        </Popup>
    );
};

export default LoginPopup;