import i18n from "i18next";

export function markGoalAsWatched(videoId) {
    const goals = JSON.parse(localStorage.getItem('goalsWatched')) || [];
    if (!goals.includes(videoId))
        goals.push(videoId);
    localStorage.setItem('goalsWatched', JSON.stringify(goals));
}

export function isGoalWatched(videoId) {
    const goals = JSON.parse(localStorage.getItem('goalsWatched')) || [];
    return goals.includes(videoId);
}

export function getGoalsWatched() {
    return JSON.parse(localStorage.getItem('goalsWatched')) || [];
}

export function markGoalAsFavorite(videoId) {
    const goals = JSON.parse(localStorage.getItem('goalsFavorite')) || [];
    if (!goals.includes(videoId))
        goals.push(videoId);
    localStorage.setItem('goalsFavorite', JSON.stringify(goals));
}

export function unmarkGoalAsFavorite(videoId) {
    const goals = JSON.parse(localStorage.getItem('goalsFavorite')) || [];
    const index = goals.indexOf(videoId);
    if (index > -1)
        goals.splice(index, 1);
    localStorage.setItem('goalsFavorite', JSON.stringify(goals));
}

export function isGoalFavorite(videoId) {
    const goals = JSON.parse(localStorage.getItem('goalsFavorite')) || [];
    return goals.includes(videoId);
}

export function getFavoriteGoals() {
    return JSON.parse(localStorage.getItem('goalsFavorite')) || [];
}

export function timeAgo(prevDate) {
    const previous = new Date(prevDate);
    const diff = Number(new Date()) - previous;
    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;
    let result;
    switch (true) {
        case diff < day:
            return i18n.t('utils.today');
        case diff < month:
            result = Math.round(diff / day);
            return result > 1 ? i18n.t('utils.daysAgo').replace('{days}', result) : i18n.t('utils.dayAgo').replace('{days}', result);
        case diff < year:
            result = Math.round(diff / month);
            if (result < 3)
                return result > 1 ? i18n.t('utils.monthsAgo').replace('{months}', result) : i18n.t('utils.monthAgo').replace('{months}', result);
            break;
        default:
            return previous.toLocaleDateString();
        }
};

export function timecodeToSeconds(timecode) {
    const parts = timecode.split(':');
    return parseInt(parts[0]) * 60 + parseInt(parts[1]);
}

export function isPasswordValid(password) {
    // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
    return regex.test(password);
}