import { useState } from 'react';

const Container = (props) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="container pop">
            {props.title === undefined ? '' :
                <div className={"flex justify-between align-center full-width " + (props.wrapper === undefined ? '' : 'pointer')} onClick={() => { if (props.wrapper !== undefined) setOpen(!open)}}>
                    <h3 className="lexend">{props.title}</h3>
                    {
                        props.wrapper === undefined ? '' :
                            <i className={"fa fa-angle-down fa-2x transition " + (open ? 'rotate-180' : '')}></i>
                    }
                </div>
            }
            {
                props.titleBorder === undefined ? '' :
                    (props.wrapper && open) || !props.wrapper ? <div className="border-top"></div> : ''
            }
            <div className="flex-column gap-10" style={{ marginLeft: `${props.margin}px`, marginBottom: (props.wrapper && open) ? `${props.margin}px` : '0px'} }>
                {
                    props.wrapper === undefined ? props.children :
                        <div className={"flex-column gap-10 " + (open ? '' : 'hidden')}>
                            {props.children}
                        </div>
                }
            </div>
        </div>
    );
}

export default Container;