import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/css/base.css';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from './locales/translations.json';
import Profile from './pages/Profile';
import Goal from './pages/Goal';
import Home from './pages/Home';
import NotFound from './pages/Error404';
import Goals from './pages/Goals';
import Players from './pages/Players';
import Player from './pages/Player';
import GoalOfFame from './pages/GoalOfFame';
import Privacy from './pages/Privacy';
import Suggestions from './pages/Suggestions';
import PasswordResetChange from './pages/PasswordResetChange';

const DEFAULT_LANG = navigator.language.split(/[-_]/)[0] || 'en';

if (!localStorage.getItem('lang')) {
  localStorage.setItem('lang', DEFAULT_LANG);
}

i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    lng: localStorage.getItem('lang'),
    fallbackLng: DEFAULT_LANG,

    interpolation: {
      escapeValue: false
    }
  });

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/goals" element={<Goals />} />
        <Route path="/goals/:goalId" element={<Goal />} />
        <Route path="/goal-of-fame" element={<GoalOfFame />} />
        <Route path="/players" element={<Players />} />
        <Route path="/players/:playerId" element={<Player />} />

        <Route path="/password/reset/change/:token" element={<PasswordResetChange />} />

        <Route path="/suggestions" element={<Suggestions />} />
        <Route path="/privacy" element={<Privacy />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
