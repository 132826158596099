const List = (props) => {
    return (
        <div className="container pop">
            {props.title === undefined ? '' :
                <div className="flex justify-between align-center full-width">
                    <h3 className="lexend">{props.title}</h3>
                    {props.button}
                </div>
            }
            <div className={"list-children " + (props.title === undefined ? '' : 'border-top')}>
                {props.children}
            </div>
        </div>
    );
};

const ListItem = (props) => {
    const content = (
        <div className="flex gap-20 align-center">
                {
                    (props.img === undefined) ? '' :
                        <img className="rounded large-border" src={props.img} alt={props.title} style={{ width: '55px', height: '55px', objectFit: 'cover', backgroundColor: '#fff' }} />
                }
                <div className="flex-column gap-10 justify-center">
                    <p className="semi-bold">{props.label}</p>
                    {
                        (props.ndLabel === undefined) ? '' :
                            <p className="secondary-text">{props.ndLabel}</p>
                    }
                </div>
        </div>
    );

    return (
        <li className="list-item pop" key={props.key}>
            {
                props.href === undefined ? content :
                    <a className="full-width" href={props.href}>{content}</a>
            }
        </li>
    );
}

export { List, ListItem };