import { useTranslation } from "react-i18next";
import NavBar from "./NavBar";
import useDarkmode from "../hooks/useDarkmode";

const Page = (props) => {
    const { t } = useTranslation();
    const [darkmode] = useDarkmode();
    const isImg = props.img !== undefined && props.img.startsWith("http");

    if (document.title === "DIMA Goals" && props.title !== undefined)
        document.title = props.title + " | DIMA Goals";

    return (
        <div className={"page " + (darkmode ? 'dark' : '')}>
            <NavBar url={props.url} />
            <main>
                {
                    <div className="page__header flex gap-20 align-center justify-center ">
                        {
                            (props.img === undefined) ? '' :
                                !props.img.startsWith("http") ? <i className={'fa fa-2x fa-' + props.img} aria-hidden="true"></i> :
                                    <img className="rounded large-border" src={props.img} alt={props.title} style={{ width: '60px', height: '60px', objectFit: 'cover', backgroundColor: '#fff' }} />
                        }
                        <h1 className={"lexend black " + (props.titleUpper ? 'uppercase ' : '') + (isImg ? 'min-content' : '')} style={props.titleSize && { fontSize: props.titleSize }}>{props.title}</h1>
                    </div>
                }
                {props.children}
            </main>

            <footer className="flex-column align-center gap-20">
                <div className="flex justify-between align-center gap-10 full-width">
                    <a className="link" href="/privacy"><i className="fa fa-info-circle fa-lg" aria-hidden="true"></i> {t("privacy.title")}</a>
                    <a className="link" href="/suggestions"><i className="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i> {t('suggestions.wordPlural')}</a>
                </div>
                <div className="flex justify-center align-center gap-10 full-width secondary-text">
                    <a className="link" href="mailto:contact@dimagoals.com"><i className="fa fa-envelope" aria-hidden="true"></i> Contact</a> |
                    <a className="link" href="https://twitter.com/anasuuuuh" target="_blank" rel="noreferrer"><i className="fa fa-twitter fa-lg" aria-hidden="true"></i> by Anas</a>
                </div>
            </footer>
        </div>
    )
};

export default Page;