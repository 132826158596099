import { useTranslation } from "react-i18next";
import useCountdown from "../hooks/useCountdown";

const Countdown = ({ date }) => {
    const { t } = useTranslation();
    const { days, hours, minutes } = useCountdown(date);

    return (
        <p className="text-center secondary-text">
            {t('goalOfFame.timeRemaining')} : 
            <span className="secondary-text italic"> {days > 0 ? days : ''} {days > 0 ? ((days > 1 ? t('utils.dayPlural') : t('utils.daySingular')).toLowerCase()) : ''} </span>
            <span className="secondary-text italic">{hours > 0 ? hours : ''} {hours > 0 ? ((hours > 1 ? t('utils.hourPlural') : t('utils.hourSingular')).toLowerCase()) : ''} </span>
            <span className="secondary-text italic">{minutes > 0 ? minutes : ''} {minutes > 0 ? ((minutes > 1 ? t('utils.minutePlural') : t('utils.minuteSingular')).toLowerCase()) : ''} </span>
        </p>
    )
}

export default Countdown;