import Page from "../components/Page";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import NotFound from "./Error404";
import GoalsList from "../components/GoalsList";
import { getGoals, getPlayer } from "../api";

const Player = () => {
    const { t } = useTranslation();
    const id = useLocation().pathname.split("/").pop();
    const filter = `scorer=${id}`;
    const [data, setData] = useState(undefined);
    const [goals, setGoals] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);

    const loadMoreGoals = () => {
        setLoadingMore(true);
        getGoals(filter, currentPage + 1)
            .then(newData => {
                setGoals(prevGoals => [...prevGoals, ...newData.results]);
                setCurrentPage(prevPage => prevPage + 1);
                setHasNextPage(newData.next !== null);
            })
            .catch(error => {
                console.error('Error loading more goals:', error);
            })
            .finally(() => {
                setLoadingMore(false);
            });
    };

    useEffect(() => {
        getPlayer(id)
            .then(data => {
                setData(data);
                document.title = `${data.full_name} | DIMA Goals`;
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setData(null);
            });

        getGoals(filter)
            .then(data => {
                setGoals(data.results);
                setHasNextPage(data.next !== null);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setGoals(null);
            });
    }, [id]);

    if (data === null) {
        return <NotFound />;
    }

    return (
        data === undefined ? <Page><span className="loader"></span></Page> :
            <Page title={data.full_name} img={data.photo} titleUpper={true} titleSize={25}>
                {
                    goals === undefined ? <span className="loader"></span> :
                        goals.length === 0 ? <p>{t('players.noGoals')}</p> :
                            <GoalsList goals={goals} />
                }
                {
                    goals && goals.length > 0 && hasNextPage &&
                        (
                            loadingMore ?
                                <span className="loader-horizontal" /> :
                                <button className="btn btn-third flex gap-10 align-center" onClick={loadMoreGoals}><i className="fa fa-plus" aria-hidden></i>{t('loadMore')}</button>
                        )
                }
            </Page>
    );
}

export default Player;