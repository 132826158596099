import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const useLoggedIn = () => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        if (Cookies.get('token') !== undefined) {
            setLoggedIn(true);
        }
    }, []);

    return loggedIn;
}

export default useLoggedIn;