import { useTranslation } from "react-i18next";
import Page from "../components/Page";
import { getGoalsWatched, getFavoriteGoals } from "../utils";
import Cookies from "js-cookie";
import useLoggedIn from "../hooks/useLoggedIn";
import { useEffect, useState } from "react";
import LoginPopup from "../components/Authentication";
import { getAllGoals, getMyProfile } from "../api";
import GoalsList from "../components/GoalsList";
import Container from "../components/Container";
import "../assets/css/profile.css";

const ProgressBar = ({ progress, width }) => {
    return (
        <div className="progress-bar" style={{ width: `${width}px` }}>
            <div className="progress" style={{ width: `${progress.toFixed()}%` }}></div>
        </div>
    );
};

export default function Profile() {
    const loggedIn = useLoggedIn();
    const username = Cookies.get("username");
    const [goals, setGoals] = useState([]);
    const [profileStats, setProfileStats] = useState(undefined);
    const [favoriteGoals, setFavoriteGoals] = useState([]);
    const { t } = useTranslation();

    const handleLogout = (e) => {
        Cookies.remove('token');
        Cookies.remove('username');
        window.location.href = '/';
    };

    useEffect(() => {
        getAllGoals().then((data) => {
            setGoals(data);
        });
    }, []);

    useEffect(() => {
        if (goals.length > 0) {
            const favs = getFavoriteGoals();
            const favGoals = goals.filter(goal => favs.includes(goal.id));
            setFavoriteGoals(favGoals);
        }
    }, [goals]);

    useEffect(() => {
        getMyProfile().then(setProfileStats)
            .catch(err => {
                alert(t(err.message));
                setProfileStats(null);
            });
    }, []);

    return (
        <Page url='/profile' title={t("profile.title")} img="user" titleUpper>
            {
                loggedIn ?
                    <div className="flex-column gap-40 align-center full-width">
                        <h2 className="semi-bold no-margin">Hey {username}</h2>
                        <div className="flex-column gap-10 align-center" style={{ width: '90%', maxWidth: '600px' }}>
                            <Container title={t('profile.watchedGoals')} titleBorder={true}>
                                <div className="flex-column gap-20" style={{ margin: '20px 0' }}>
                                    <div className="flex justify-between align-center">
                                        <p><span className="bold text-bigger" >{getGoalsWatched().length | 0}</span> / {goals.length}</p>
                                        <span className="text-bigger">{((getGoalsWatched().length | 0) / goals.length * 100).toFixed()}%</span>
                                    </div>
                                    <ProgressBar progress={(getGoalsWatched().length | 0) / goals.length * 100} />
                                </div>
                            </Container>

                            <GoalsList goals={favoriteGoals} title={t('profile.likedGoals')} button={false} showPlayer />
                        </div>

                        <h3 className="semi-bold no-margin">{t('profile.stats.title')}</h3>
                        {
                                profileStats !== undefined && profileStats !== null ?
                                    <div className="cards align-center justify-center" >
                                        <div className="card">
                                            <span className="card__name">{t('profile.stats.suggestions')}</span>
                                            <div className="flex gap-10 align-center">
                                                <div className="flex-column gap-10 align-center">
                                                    <span className="card__value third-text"><i className="fa fa-check"></i> {profileStats.suggestions.validated}</span>
                                                    <span className="card__unit">{t('profile.stats.validated')}</span>
                                                </div>
                                                <div className="flex-column gap-10 align-center">
                                                    <span className="card__value secondary-text"><i className="fa fa-clock-o"></i> {profileStats.suggestions.pending}</span>
                                                    <span className="card__unit">{t('profile.stats.pending')}</span>
                                                </div>
                                                <div className="flex-column gap-10 align-center">
                                                    <span className="card__value error"><i className="fa fa-times"></i> {profileStats.suggestions.denied}</span>
                                                    <span className="card__unit">{t('profile.stats.denied')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <span className="card__name">{t('profile.stats.contributions')}</span>
                                            <div className="flex gap-10 align-center">
                                                <div className="flex-column gap-10 align-center">
                                                    <span className="card__value third-text"><i className="fa fa-check"></i> {profileStats.contributions.verified}</span>
                                                    <span className="card__unit">{t('profile.stats.validated')}</span>
                                                </div>
                                                <div className="flex-column gap-10 align-center">
                                                    <span className="card__value secondary-text"><i className="fa fa-clock-o"></i> {profileStats.contributions.pending}</span>
                                                    <span className="card__unit">{t('profile.stats.pending')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''
                            }
                        <button className="btn btn-red max-content" onClick={handleLogout}>{t("profile.logout")}</button>
                    </div>
                    :
                    <LoginPopup trigger={
                        <button className="btn btn-third max-content">{t("authentication.signin")}</button>
                    } />
            }
        </Page>
    );
}