import Page from "../components/Page";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import NotFound from "./Error404";
import config from "../config.json";

const PasswordResetChangeForm = ({ resetToken }) => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handlePasswordResetChange = (e) => {
        e.preventDefault();

        if (e.target.new_password.value !== e.target.confirm_new_password.value) {
            setErrorMessage(t('errors.passwordsDontMatch'));
            return;
        }

        const formData = new FormData(e.target);
        fetch(config.apiEndpoint + '/auth/password/reset/change/', {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (response.ok) {
                    setSuccessMessage(t('authentication.passwordChangeSuccess'));
                    setErrorMessage(null);
                } else {
                    response.json().then(data => {
                        setErrorMessage(t(data.error));
                    });
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
            });
    };

    return (
        <form className="flex-column gap-20" style={{ marginTop: '15px' }} onSubmit={handlePasswordResetChange}>
            {errorMessage && <p className="text-center error">{errorMessage}</p>}
            {successMessage && <p className="text-center primary-text">{successMessage}</p>}
            <input type="hidden" name="token" value={resetToken} />
            <div className="flex-column gap-10">
                <label htmlFor="password">{t('authentication.password')}</label>
                <input type="password" name="new_password" placeholder={t('authentication.passwordPlaceholder')} minLength={8} maxLength={32} required />
            </div>
            <div className="flex-column gap-10">
                <label htmlFor="confirm_password">{t('authentication.passwordConfirmation')}</label>
                <input type="password" name="confirm_new_password" placeholder={t('authentication.passwordConfirmationPlaceholder')} minLength={8} maxLength={32} required />
            </div>
            <div className="flex justify-center align-center gap-10">
                <input type="submit" className="btn btn-primary max-content no-border" value={t('authentication.passwordChangeSubmit')} />
            </div>
        </form>
    );
};

const PasswordResetChange = () => {
    const { t } = useTranslation();
    const resetToken = useLocation().pathname.split("/").pop();

    if (resetToken === null) {
        return <NotFound />;
    }

    return (
            <Page title={t('authentication.passwordChangeTitle')} img="key" titleUpper={true}>
                <PasswordResetChangeForm resetToken={resetToken} />
            </Page>
    );
}

export default PasswordResetChange;