import { createSuggestion, getSuggestions } from "../api";
import Container from "../components/Container";
import { List } from "../components/List";
import Page from "../components/Page";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import Close from "../components/Close";
import useLoggedIn from "../hooks/useLoggedIn";

const CreateSuggestionButton = () => {
    const { t } = useTranslation();

    const handleSubmit = (e, close) => {
        e.preventDefault();
        const data = new FormData(e.target);
        createSuggestion(data)
            .then((data) => {
                close();
                alert(t("goals.formSent"));
            })
            .catch(err => {
                alert(t(err.message));
            });
    };

    return (
        <Popup trigger={<button className="btn btn-secondary no-border flex gap-10 align-center"><i className="fa fa-plus" />{t('suggestions.add')}</button>} modal>
            { close =>
                <div className='flex-column gap-20 align-center justify-center full-width'>
                    <Close onClick={close} />
                    <h3 className="text-center">{t("suggestions.form.title")}</h3>
                    <p className="text-center">{t("suggestions.form.description")}</p>
                    <form className="flex-column gap-40 align-center justify-center" onSubmit={e => handleSubmit(e, close)}>
                        <input type="hidden" name="author" value={1} />
                        <div className="flex-column gap-30 align-center justify-center full-width">
                            <textarea name="description" placeholder={t("suggestions.form.descriptionPlaceholder")} required maxLength={300} />
                        </div>
                        <input className="btn btn-primary no-border" type="submit" value={t("suggestions.form.submit")} />
                    </form>
                </div>
            }
        </Popup>
    )
}

const Suggestion = ({ suggestion }) => {
    return (
        <div className="flex gap-20 align-center padding-tb">
            {
                suggestion.validated ?
                    <i className="fa fa-check primary-text"></i> :
                    suggestion.denied ?
                        <i className="fa fa-times error"></i> :
                        <i className="fa fa-clock-o secondary-text"></i>
            }
            <p>{suggestion.description}</p>
        </div>
    );
}

const Suggestions = () => {
    const { t } = useTranslation();
    const [suggestions, setSuggestions] = useState(undefined);
    const loggedIn = useLoggedIn();
    const [filter, setFilter] = useState("validated");

    useEffect(() => {
        getSuggestions(filter).then(setSuggestions)
            .catch(err => {
                alert(t(err.message));
                setSuggestions(null);
            });
    }, [filter]);

    return (
        <Page url="/" title={t('suggestions.title')} img="pencil-square-o" titleUpper>
            {
                suggestions && suggestions.length > 0 ?
                    <Container title={t("goals.filters.title")} titleBorder margin={15} wrapper>
                        <div className="flex-column gap-10" style={{ marginTop: '15px' }}>
                            <h4 className="no-margin">Status</h4>
                            <div className="flex-column gap-10" style={{ marginLeft: '15px', padding: '5px 0' }}>
                                <div className={"flex align-center gap-5 clickable-bg pointer radius " + (filter !== 'pending' ? 'disabled-clickable' : '')} onClick={() => setFilter("pending")}>
                                    <i className="fa fa-clock-o pointer"></i>
                                    <label className="pointer" htmlFor="pending">{t("suggestions.pending")}</label>
                                </div>
                                <div className={"flex align-center gap-5 clickable-bg pointer radius " + (filter !== 'validated' ? 'disabled-clickable' : '')} onClick={() => setFilter("validated")}>
                                    <i className="fa fa-check pointer"></i>
                                    <label className="pointer" htmlFor="validated">{t("suggestions.validated")}</label>
                                </div>
                                <div className={"flex align-center gap-5 clickable-bg pointer radius " + (filter !== 'denied' ? 'disabled-clickable' : '')} onClick={() => setFilter("denied")}>
                                    <i className="fa fa-times pointer"></i>
                                    <label className="pointer" htmlFor="denied">{t("suggestions.denied")}</label>
                                </div>
                            </div>
                        </div>
                    </Container> :
                    ''
            }
            {
                suggestions === undefined ?
                    <span className="loader"></span> :
                    <List title={t("suggestions.wordPlural")} button={loggedIn ? <CreateSuggestionButton /> : ''}>
                        {
                            suggestions === null || suggestions.length === 0 ?
                                <p className="text-center padding">{t("suggestions.noSuggestions")}</p> :
                                suggestions.map((suggestion, index) => {
                                    return <Suggestion key={index} suggestion={suggestion} />
                                })
                        }
                    </List>
            }
        </Page>
    );
}

export default Suggestions;