import Page from "../components/Page";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import SearchBar from "../components/SearchBar";
import { List, ListItem } from "../components/List";
import { getPlayers } from "../api";

const Players = () => {
    const { t } = useTranslation();
    const [players, setPlayers] = useState([]);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);

    const fetchData = () => {
        setLoadingMore(true);
        const filter = search.length > 0 ? `name=${search}` : "";
        getPlayers(filter, search.length > 0 ? 1 : currentPage)
            .then(data => {
                if (search.length > 0) {
                    setPlayers(data.results);
                    setHasNextPage(false);
                    setCurrentPage(1);
                } else {
                    setPlayers([...players, ...data.results]);
                    setCurrentPage(currentPage + 1);
                    setHasNextPage(data.next !== null);
                }
                setLoadingMore(false);
            })
            .catch(error => {
                setPlayers(null);
            });
    };

    useEffect(() => {
        setCurrentPage(1);
        fetchData();
    }, [search]);

    const onSubmit = (e) => {
        e.preventDefault();
        setSearch(e.target.search.value);
    };

    const loadMore = () => {
        if (hasNextPage) {
            fetchData();
        } else {
            document.onscroll = null;
        }
    };

    const handleScroll = (e) => {
        if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
            loadMore();
        }
    };

    document.onscroll = handleScroll;

    if (players === null) {
        return (
            <Page url="/goals">
                <h1>{t('goals.title')}</h1>
                <p>{t('errors.fetch')}</p>
            </Page>
        )
    }

    return (
        <Page url="/players" title={t('players.title')} img="users fa-1x" titleUpper>
            <form className="flex gap-10 align-center justify-center" onSubmit={onSubmit}>
                <SearchBar name="search" id="players-search" placeholder={t('players.searchPlaceholder')} />
                <input type="submit" className="btn btn-primary no-border" value={t('players.search')} />
            </form>
            {
                players === undefined ? <span className="loader"></span> :
                    players.length === 0 ? <p>{t('players.noPlayers')}</p> :
                        <List title={t('players.title')}>
                            {players.sort((a, b) => b.nb_goals - a.nb_goals).map(player => (
                                <ListItem key={player.id} href={`/players/${player.id}`} img={player.photo} label={player.full_name} ndLabel={` ${player.nb_goals} ${player.nb_goals > 1 ? t('goals.wordPlural').toLowerCase() : t('goals.word').toLowerCase()}`}/>
                            ))}
                        </List>
            }
            {
                players && players.length > 0 && hasNextPage &&
                    (
                        loadingMore ?
                            <span className="loader-horizontal"></span> :
                            <button className="btn btn-third flex gap-10 align-center" onClick={loadMore}><i className="fa fa-plus" aria-hidden></i>{t('loadMore')}</button>
                    )
            }
        </Page>
    );
}

export default Players;