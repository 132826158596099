import useScreenSize from "../hooks/useScreenSize";
import { useTranslation } from "react-i18next";
import useDarkmode from "../hooks/useDarkmode";
import { timeAgo } from "../utils";

const GoalComponent = (props) => {
    const { t } = useTranslation();
    const [darkmode] = useDarkmode();
    const screenSize = useScreenSize();
    const competitionImgStyle = { width: '20px', height: '20px', objectFit: 'contain' };
    const playerImgStyle = screenSize.width > 600 ? { width: '55px', height: '55px', objectFit: 'contain', backgroundColor: '#fff' } : { width: '45px', height: '45px', objectFit: 'contain', backgroundColor: '#fff' };

    return (
        <div className={`${props.className} full-width radius ` + (props.individual !== undefined ? 'secondary-bg padding clickable-bg' : '')} style={{ maxWidth: '600px' }}>
            <a href={props.href !== undefined ? props.href : `/goals/${props.goal.id}`}>
                <div className="flex gap-20 full-width">
                    {
                        props.showPlayer ?
                            <div className="flex-column gap-10 justify-center">
                                <img className="rounded large-border" src={props.goal.scorer.photo} alt={props.goal.scorer.full_name} style={playerImgStyle} />
                            </div> :
                            !props.showPlayer ?
                            <div className="flex-column gap-10 justify-center">
                                <img className="rounded large-border" src={props.goal.team.logo} alt={props.goal.team.name} style={{ ...playerImgStyle, padding: '5px' }} />
                            </div> : ''

                    }
                    <div className="flex justify-between gap-10 align-center full-width">
                        {
                            props.showPlayer ?
                                <div className="flex-column gap-10 justify-center">
                                            <div className="flex align-center gap-10">
                                                <p className="semi-bold">{props.goal.scorer.full_name} <i className="secondary-text regular">- {timeAgo(props.goal.date)}</i></p>
                                            </div>
                                            <div className="flex align-center gap-10">
                                                <div className="flex gap-5 align-center">
                                                    <img src={props.goal.team.logo} alt={props.goal.team.name} style={competitionImgStyle} />
                                                    <p className="secondary-text">{props.goal.team.name}</p>
                                                </div>
                                                <p className="secondary-text">vs</p>
                                                <div className="flex gap-5 align-center">
                                                    <img src={props.goal.opponent.logo} alt={props.goal.opponent.name} style={competitionImgStyle} />
                                                    <p className="secondary-text italic">{props.goal.opponent.name}</p>
                                                </div>
                                            </div>
                                    <div className="flex justify-between align-center">
                                        <div className="flex gap-5 align-center">
                                            <img src={darkmode ? props.goal.competition.logo : props.goal.competition.logo.replace('/dark', '')} alt={props.goal.competition.name} style={competitionImgStyle} />
                                            <p className="secondary-text italic">{props.goal.competition.name}</p>
                                        </div>
                                        {
                                            screenSize.width < 600 && props.showVotes &&
                                                <span>{props.goal.votes_count} {props.goal.votes_count > 1 ? t("goalOfFame.votePlural").toLowerCase() : t("goalOfFame.voteSingular").toLowerCase()}</span>
                                        }
                                    </div>
                                </div> :
                                <div className="flex-column gap-10 justify-center">
                                    <div className="flex gap-5 align-center">
                                        <img src={darkmode ? props.goal.competition.logo : props.goal.competition.logo.replace('/dark', '')} alt={props.goal.competition.name} style={competitionImgStyle} />
                                        <p className="secondary-text italic">{props.goal.competition.name}</p>
                                    </div>
                                    {
                                        screenSize.width > 600 ?
                                            <div className="flex align-center gap-10">
                                                <p className="semi-bold">{props.goal.team.name}</p>
                                                <p>vs</p>
                                                <div className="flex gap-5 align-center">
                                                    <img src={props.goal.opponent.logo} alt={props.goal.opponent.name} style={competitionImgStyle} />
                                                    <p className="semi-bold">{props.goal.opponent.name}</p>
                                                </div>
                                            </div> :
                                            <div className="flex gap-10 align-center">
                                                <span>vs</span>
                                                <img src={props.goal.opponent.logo} alt={props.goal.opponent.name} style={competitionImgStyle} />
                                                <p>{props.goal.opponent.name}</p>
                                            </div>
                                    }
                                    <p className="secondary-text italic">{timeAgo(props.goal.date)}</p>
                                </div>
                        }
                        <div className="flex-column gap-10">
                            {
                                props.showMinute && screenSize.width > 600 ?
                                    <span className="minute">{props.goal.minute}'</span> : ''
                            }
                            {
                                props.showVotes && screenSize.width > 600 ?
                                    <span className="">{props.goal.votes_count} {props.goal.votes_count > 1 ? t("goalOfFame.votePlural").toLowerCase() : t("goalOfFame.voteSingular").toLowerCase()}</span> : ''
                            }
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default GoalComponent;