import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CreatableSelect from 'react-select/creatable';
import { createTeam, getTeams } from '../api';

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string, value: string) => ({
  label,
  value: value
});

const TeamSelect = ({ name }: { name: string }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [teams, setTeams] = useState<Option[]>([]);
  const [value, setValue] = useState<Option | null>();

  const styles = {
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: !state.isFocused ? 'var(--secondary-background-color)' : 'var(--secondary-background-color-light)',
      ':hover': {
        backgroundColor: 'var(--secondary-background-color-light)'
      },
      ':active': {
        backgroundColor: 'var(--secondary-background-color)'
      },
    }),
  };

  useEffect(() => {
    getTeams()
      .then(data => {
        setTeams(data.map((team: { id: number; name: string; }) => ({ value: team.id, label: team.name })));
      })
      .catch(error => {
        alert(t("errors.fetch"));
      });
  }, []);

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    createTeam({ name: inputValue })
      .then((data) => {
        const newOption = createOption(inputValue, data.id.toString());
        setIsLoading(false);
        setTeams([...teams, newOption]);
        setValue(newOption);
      })
      .catch((error) => {
        alert(t("errors.create"));
        setIsLoading(false);
      });
  };

  return (
    <CreatableSelect
      styles={styles}
      classNamePrefix="select"
      name={name || 'team'}
      placeholder={t(`goals.form.create.teamPlaceholder`)}
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={(newValue) => setValue(newValue)}
      onCreateOption={handleCreate}
      options={teams}
      value={value}
      required
    />
  );
};

export default TeamSelect;