import { getGoals, getPlayers } from "../api";
import NavBar from "../components/NavBar";
import GoalComponent from "../components/GoalComponent";
import config from "../config";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useScreenSize from "../hooks/useScreenSize";

const Home = () => {
    const { t } = useTranslation();
    const screenSize = useScreenSize();
    const [lastGoals, setLastGoals] = useState(undefined);
    const [players, setPlayers] = useState(undefined);

    useEffect(() => {
        getGoals('', 1, 3).then((data) => {
            setLastGoals(data.results);
        });

        getPlayers('', 1, 30).then((data) => {
            setPlayers(data.results);
        });
    }, []);

    return (
        <div className="page">
            <NavBar url="/" />
            <section className="banner" id="welcome">
                <video className="banner-background" autoPlay={true} loop={true} controls={false} playsInline muted width={screenSize.width} style={{ objectFit: 'cover', marginTop: '-190px' }}>
                    <source src="/assets/video/banner.mp4" type="video/mp4" />
                </video>
                        <div className="flex-column padding-lr extra-bold">
                            <h2 className="font-xxl lexend">{t('home.welcome.title')}</h2>
                            <div className="flex-column gap-20">
                                <p className="semi-bold" style={{fontSize: '19px'}}>{t('home.welcome.description')}</p>
                                <a className="btn btn-primary semi-bold max-content" href="#last-goals">{t('readMore')}</a>
                            </div>
                        </div>
            </section>
            <section className="section-primary padding-lr flex-column gap-50" id="last-goals">
                    <div className="text-center">
                        <h2 className="font-xxl lexend">{t('home.lastGoals.title')}</h2>
                        <p>{t('home.lastGoals.description')}</p>
                    </div>
                    <div className="flex flex-wrap justify-center align-center gap-20 full-width" style={{ maxWidth: '1400px' }}>
                        {
                            lastGoals === undefined ? <span className="loader"></span> :
                            lastGoals.map((goal, index) => {
                                return (
                                    <GoalComponent key={index} goal={goal} individual showPlayer />
                                )
                            })  
                        }
                    </div>
                    <a className="btn btn-primary semi-bold" href="/goals">{t('home.seeAllGoals')}</a>
            </section>
            <section className="section-primary padding-lr primary-bg-dark" id="goal-of-fame">
                <div className="section-split">
                    <div>
                        <h2 className="font-xxl lexend white-text">{t('home.goalOfFame.title')}</h2>
                        <div className="flex-column gap-20">
                            <p className="white-text">{t('home.goalOfFame.description')}</p>
                            <a className="btn btn-primary semi-bold max-content" href="/goal-of-fame">{t('goalOfFame.title')}</a>
                        </div>
                    </div>
                    <div>
                        <img src="/assets/img/goal_month_d.png" className="radius full-width full-height cover" alt="Home 1" />
                    </div>
                </div>
            </section>
            <section className="section-primary padding-lr" id="contribute">
                <div className="section-split grid-reverse">
                    <div>
                        <h2 className="font-xxl lexend">{t('home.contribute.title')}</h2>
                        <div className="flex-column gap-20">
                            <p>{t('home.contribute.description')}</p>
                            <a className="btn btn-primary semi-bold max-content" href="/goals">{t('home.seeAllGoals')}</a>
                        </div>
                    </div>
                    <div>
                        <img src="https://www.thesun.co.uk/wp-content/uploads/2022/12/2022-round-16-soccer-match-781081918.jpg" className="radius full-width full-height cover" alt="Home 1" />
                    </div>
                </div>
            </section>
            <section className="section-primary padding-lr secondary-bg" id="players">
                <div className="section-split">
                    <div>
                        <h2 className="font-xxl lexend">{t('home.players.title')}</h2>
                        <div className="flex-column gap-20">
                            <p>{t('home.players.description')}</p>
                            <a className="btn btn-primary semi-bold max-content" href="/players">{t('home.seeAllPlayers')}</a>
                        </div>
                    </div>
                    <div className="flex justify-center align-center flex-wrap gap-10 secondary-bg-light padding radius">
                        {
                            players === undefined ? <span className="loader"></span> :
                            players.map((player, index) => {
                                return (
                                    <a key={index} href={`/players/${player.id}`}>
                                        <img className="rounded large-border surface" src={player.photo} alt="Home 1" style={{ width: '30px', height: '30px', objectFit: 'contain', backgroundColor: '#fff' }} title={player.full_name} />
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section className="section-primary padding-lr" id="featured-player">
                <div className="section-split grid-reverse">
                    <div>
                        <h2 className="font-xxl lexend">{t('home.featuredPlayer.title')}</h2>
                        <div className="flex-column gap-20">
                            <p>{t('home.featuredPlayer.description')}</p>
                            <a className="btn btn-primary semi-bold max-content" href="/players">{t('home.seePlayersLeaderboard')}</a>
                        </div>
                    </div>
                    <div>
                        <a className="flex align-center gap-20 secondary-bg padding radius clickable-bg" href={players ? `/players/${players[0].id}` : '#'}>
                            <i className="fa fa-2x fa-trophy" style={{ color: '#FFD700' }} aria-hidden="true"></i>
                            <img className="rounded large-border surface" src={players ? players[0].photo : config.defaultPlayerPhoto} alt="Home 1" style={{ width: '55px', height: '55px', objectFit: 'contain', backgroundColor: '#fff' }} />
                            <div className="flex-column gap-10">
                                <span className="bold">{players ? players[0].full_name : ''}</span>
                                <span className="secondary-text">{players ? players[0].nb_goals : '?'} {t('goals.wordPlural').toLowerCase()}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </section>

            <footer className="flex-column align-center gap-20">
                <div className="flex justify-between align-center gap-10 full-width">
                    <a className="link" href="/privacy"><i className="fa fa-info-circle fa-lg" aria-hidden="true"></i> {t("privacy.title")}</a>
                    <a className="link" href="/suggestions"><i className="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i> {t('suggestions.wordPlural')}</a>
                </div>
                <div className="flex justify-center align-center gap-10 full-width secondary-text">
                <a className="link" href="mailto:contact@dimagoals.com"><i className="fa fa-envelope" aria-hidden="true"></i> Contact</a> |
                    <a className="link" href="https://twitter.com/anasuuuuh" target="_blank" rel="noreferrer"><i className="fa fa-twitter fa-lg" aria-hidden="true"></i> by Anas</a>
                </div>
            </footer>
        </div>
    );
}

export default Home;