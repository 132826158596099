import { useState, useEffect } from 'react';
import { isGoalFavorite, unmarkGoalAsFavorite, markGoalAsFavorite } from '../utils';

const useFavorites = (goalId) => {
    const [favorite, setFavorite] = useState(false);

    useEffect(() => {
        setFavorite(isGoalFavorite(goalId));
    }, [goalId]);

    const toggleFavorite = () => {
        if (favorite) {
            unmarkGoalAsFavorite(goalId);
        } else {
            markGoalAsFavorite(goalId);
        }
        setFavorite(!favorite);
    };

    return [favorite, toggleFavorite];
}

export default useFavorites;