import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CreatableSelect from 'react-select/creatable';
import { getCompetitions, createCompetition } from '../api';

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string, value: string) => ({
  label,
  value: value
});

const CompetitionSelect = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [competitions, setCompetitions] = useState<Option[]>([]);
  const [value, setValue] = useState<Option | null>();

  const styles = {
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: !state.isFocused ? 'var(--secondary-background-color)' : 'var(--secondary-background-color-light)',
      ':hover': {
        backgroundColor: 'var(--secondary-background-color-light)'
      },
      ':active': {
        backgroundColor: 'var(--secondary-background-color)'
      },
    }),
  };

  useEffect(() => {
    getCompetitions()
      .then(data => {
        setCompetitions(data.map((team: { id: number; name: string; }) => ({ value: team.id, label: team.name })));
      })
      .catch(error => {
        alert(t("errors.fetch"));
      });
  }, []);

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    createCompetition({ name: inputValue })
      .then((data) => {
        const newOption = createOption(inputValue, data.id.toString());
        setIsLoading(false);
        setCompetitions([...competitions, newOption]);
        setValue(newOption);
      })
      .catch((error) => {
        alert(t("errors.create"));
        setIsLoading(false);
      });
  };

  return (
    <div>
      <CreatableSelect
        styles={styles}
        classNamePrefix="select"
        name="competition"
        placeholder={t(`goals.form.create.competitionPlaceholder`)}
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={(newValue) => setValue(newValue)}
        onCreateOption={handleCreate}
        options={competitions}
        value={value}
        required
      />
    </div>
  );
};

export default CompetitionSelect;
