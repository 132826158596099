import "../assets/css/goal.css";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Page from "../components/Page";
import NotFound from "./Error404";
import { getGoal, getVotes, reportClip, suggestClip, unvote, vote } from "../api";
import GoalComponent from "../components/GoalComponent";
import Popup from "reactjs-popup";
import Close from "../components/Close";
import ReactPlayer from 'react-player';
import { markGoalAsWatched } from "../utils";
import useLoggedIn from "../hooks/useLoggedIn";
import Cookies from "js-cookie";
import useScreenSize from "../hooks/useScreenSize";
import useFavorites from "../hooks/useFavorites";

const VideoForm = ({ goalId, close }) => {
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        suggestClip(goalId, data)
            .then(data => {
                alert(t("goals.formSent"));
                close();
            })
            .catch(error => {
                alert(t("errors.post"));
            });
    }

    return (
        <form className="flex-column gap-40 align-center justify-center" method="POST" onSubmit={onSubmit}>
            <div className="flex-column gap-20 align-center justify-center full-width">
                <input type="hidden" name="contributor" value={Cookies.get('username')} />
                <input type="hidden" name="goal" value={goalId} />
                <fieldset>
                    <label>{t("goals.form.suggest.videoSource")}</label>
                    <input type="url" name="video_url" placeholder="https://example.com/video.mp4" required />
                </fieldset>
                <fieldset>
                    <label>{t("goals.form.suggest.timecodeStart")}</label>
                    <input name='timecode_start' placeholder="01:43" />
                </fieldset>
                <fieldset>
                    <label>{t("goals.form.suggest.timecodeEnd")}</label>
                    <input name='timecode_end' placeholder="02:12" />
                </fieldset>
            </div>
            <input className="btn btn-primary no-border" type="submit" value={t("goals.form.suggest.submit")} />
        </form>
    );  
}

const VideoReportForm = ({ goalId, close }) => {
    const { t } = useTranslation();

    const onSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        reportClip(goalId, data)
            .then(data => {
                alert(t("goals.formSent"));
                close();
            })
            .catch(error => {
                alert(t("errors.post"));
            }
            );
    }

    return (
        <form className="flex-column gap-40 align-center justify-center" method="POST" onSubmit={onSubmit}>
            <div className="flex-column gap-20 align-center justify-center full-width">
                <input type="hidden" name="contributor" value={Cookies.get('username')} />
                <input type="hidden" name="goal" value={goalId} />
                <p className="text-center">{t("goals.form.report.description")}</p>
                <fieldset>
                    <label>{t("goals.form.report.reason")}</label>
                    <textarea maxLength={100} type="text" name="reason" placeholder={t("goals.form.report.reasonPlaceholder")} required />
                </fieldset>
            </div>
            <input className="btn btn-red no-border" type="submit" value={t("goals.form.report.submit")} />
        </form>
    );
};

export default function Goal() {
    const { t } = useTranslation();
    const id = parseInt(useLocation().pathname.split("/").pop());
    const loggedIn = useLoggedIn();
    const screenSize = useScreenSize();
    const [data, setData] = useState(undefined);
    const [voted, setVoted] = useState(undefined);
    const [canVote, setCanVote] = useState(false);
    const [favorite, toggleFavorite] = useFavorites(id);

    useEffect(() => {
        getVotes()
            .then(data => {
                if (data !== undefined) {
                    setVoted(data.goal);
                }
            })
            .catch(error => {
                setVoted(undefined);
            });
    }, []);

    const handleVote = () => {
        if (loggedIn) {
            if (voted === undefined)
                vote(id)
                    .then(ok => {
                        if (ok) {
                            window.location.reload();
                        }
                    });
        }
        else
            alert(t("errors.400"));
    }

    const handleUnvote = () => {
        if (loggedIn) {
            if (voted !== undefined)
                unvote(id)
                    .then(ok => {
                        if (ok) {
                            window.location.reload();
                        }
                    });
        }
        else
            alert(t("errors.400"));
    };
                
    useEffect(() => {
        getGoal(id)
            .then(data => {
                setData(data)
                let date = new Date(data.date);
                let today = new Date();
                setCanVote(date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth());
                document.title = `${t('goals.word')} ${data.scorer.full_name} (${data.team.name}) | DIMA Goals`;
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setData(null);
            });
    }, [id]);

    if (data === null) {
        return <NotFound />
    }

    return (
        data === undefined ? <span className="loader"></span> :
            <Page title={t('goals.word')} img="futbol-o" titleUpper>
                        <GoalComponent goal={data} href={`/players/${data.scorer.id}`} showPlayer individual />
                        <div className="flex-column align-center justify-center">
                            <div className="flex gap-10 align-center justify-center">
                                <h3 className="text-center">{t("goals.voteTitle")}</h3>
                                {
                                    voted !== undefined ?
                                        <Popup trigger={
                                            <button className={`btn btn-${canVote ? 'primary' : 'secondary'} flex gap-10 no-border align-center`} disabled={!canVote} title={!canVote ? t("goals.voteFinished") : ''}>
                                                {voted === id ? <i className="fa fa-check" aria-hidden="true" /> : <i className="fa fa-thumbs-up" aria-hidden="true" />}
                                                {voted === id ? t("goals.voted") : t("goals.vote")}
                                            </button>
                                        } modal>
                                            {close => (
                                                <div className='flex-column gap-20 align-center justify-center full-width'>
                                                    <Close onClick={close} />
                                                    <h3 className="text-center">{t("goals.voteAlready")}</h3>
                                                    <p>{t("goals.voteAlreadyDescription")}</p>
                                                    <div className="flex gap-10 align-center justify-center">
                                                        <a className="btn btn-secondary" href={`/goals/${voted}`} target="_blank" rel="noreferrer">{t("goals.voteAlreadyLink")}</a>
                                                        <button className="btn btn-red no-border" onClick={handleUnvote}>{t("goals.voteRemove")}</button>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup> :
                                        <button className={`btn btn-${canVote ? 'primary' : 'secondary'} flex gap-10 no-border`} onClick={canVote ? handleVote : undefined} disabled={!canVote}>
                                            <i className="fa fa-thumbs-up" aria-hidden="true" />
                                            {t("goals.vote")}
                                        </button>
                                }
                            </div>
                            {!canVote ? <p className="text-center secondary-text">{t("goals.voteFinished")}</p> : ''}
                        </div>
                            <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', padding: '0 10px' }} className='align-center justify-center border-box '>
                                {
                                    data.previous_goal ?
                                        <a className="goal-navigation left" href={`/goals/${data.previous_goal}`} title={t("goals.previous")} style={{ maxHeight: ((screenSize.width - 50) * 9) / 16 }}>
                                        </a> :
                                        <div className="goal-navigation" style={{ maxHeight: ((screenSize.width - 50) * 9) / 16 }}></div>
                                }
                                {
                                    data.video &&
                                        <div>
                                            <ReactPlayer className="radius" url={data.video} loop style={{ maxWidth: '600px', maxHeight: ((screenSize.width - 50) * 9) / 16 }} config={{ youtube: { playerVars: { start: data.timecode_start, end: data.timecode_end }} }} onStart={markGoalAsWatched(id)} width={screenSize.width - 50} />
                                            <p className="secondary-text italic">{t('goals.publishedBy')} : {data.contributor_username}</p>
                                        </div>
                                }
                                {
                                    data.next_goal ?
                                        <a className="goal-navigation right" href={`/goals/${data.next_goal}`} title={t("goals.next")} style={{ maxHeight: ((screenSize.width - 50) * 9) / 16 }}>
                                        </a> :
                                        <div className="goal-navigation" style={{ maxHeight: ((screenSize.width - 50) * 9) / 16 }}></div>
                                }
                            </div>
                        <div className="flex align-center justify-center full-width">
                            <button className={`btn btn-${favorite ? 'secondary' : 'primary'} flex gap-10 no-border`} onClick={toggleFavorite}>
                                {favorite ? <i className="fa fa-heart" aria-hidden="true" /> : <i className="fa fa-heart-o" aria-hidden="true" />}
                                {favorite ? t("goals.likedSingular") : t("goals.like")}
                            </button>
                        </div>
                        <div className="flex flex-wrap gap-10 align-center justify-center full-width">
                            {
                                    <Popup trigger={<button className='btn btn-secondary no-border'>{t("goals.form.suggest.title")}</button>} modal>
                                        {close => (
                                            loggedIn ?
                                                <div className='flex-column gap-20 align-center justify-center full-width'>
                                                    <Close onClick={close} />
                                                    <h3>{t("goals.form.suggest.title")}</h3>
                                                    <VideoForm goalId={id} close={close} />
                                                </div> :                                                
                                                <div className='flex-column gap-20 align-center justify-center full-width'>
                                                    <Close onClick={close} />
                                                    <p className="text-center">{t("errors.400")}</p>
                                                </div>
                                        )}
                                    </Popup>
                            }
                            <Popup trigger={<button className='btn btn-red no-border'>{t("goals.form.report.title")}</button>} modal>
                                {close => (
                                    <div className='flex-column gap-20 align-center justify-center full-width'>
                                        <Close onClick={close} />
                                        <h3>{t("goals.form.report.title")}</h3>
                                        <VideoReportForm goalId={id} close={close} />
                                    </div>
                                )}
                            </Popup>
                        </div>
            </Page>
    );
}
